<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            {{ title }}
            <v-spacer></v-spacer>
            <v-btn
                v-if="!createForm"
                depressed
                color="primary"
                rounded
                icon
                class=""
                @click="update"
            >
                <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn
                v-if="!createForm"
                depressed
                rounded
                color="primary"
                @click="openDeleteDialog"
                icon
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-card-title>
        <v-dialog
            v-model="deleteDialog"
            :retain-focus="false"
            persistent
            max-width="400px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">Delete Contact</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this contact?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteContact"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                :disabled="!createForm && !isEditing"
            >
                <v-row justify="center">
                    <input
                        v-if="createForm || isEditing"
                        ref="profilePic"
                        name="profilePic"
                        accept="image/*"
                        type="file"
                        class="d-none"
                        @change="onFilePicked"
                    />
                    <v-hover v-slot:default="{ hover }">
                        <v-avatar
                            color="primary"
                            size="96"
                            class="avatar"
                            @click="$refs.profilePic.click()"
                        >
                            <v-img v-if="image" :src="image">
                                <v-fade-transition origin="center">
                                    <div
                                        v-if="hover"
                                        class="change-picture dark"
                                    >
                                        <v-icon color="white"
                                            >mdi-camera</v-icon
                                        >
                                    </div>
                                </v-fade-transition>
                            </v-img>
                            <v-icon
                                v-else
                                :size="!hover ? 96 : null"
                                color="white"
                            >
                                {{
                                    hover ? 'mdi-camera' : 'mdi-account-circle'
                                }}
                            </v-icon>
                        </v-avatar>
                    </v-hover>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="contact.name"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            prepend-icon="mdi-account-tie"
                            label="Name *"
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="contact.email"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.email]"
                            prepend-icon="mdi-email"
                            label="Email *"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <vue-tel-input
                            v-model="contact.phone"
                            mode="international"
                            :inputOptions="inputOptions1"
                            :disabled="!createForm && !isEditing"
                            validCharactersOnly
                        />
                    </v-col>
                    <v-col cols="6">
                        <vue-tel-input
                            v-model="contact.phone2"
                            mode="international"
                            :inputOptions="inputOptions2"
                            :disabled="!createForm && !isEditing"
                            validCharactersOnly
                        ></vue-tel-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-if="!createForm && !isEditing"
                            :readonly="!createForm && !isEditing"
                            v-model="contact.country"
                            hide-details
                            prepend-icon="mdi-earth"
                            label="Country "
                            required
                        />

                        <v-autocomplete
                            v-if="createForm || isEditing"
                            v-model="contact.country"
                            :items="this.countries"
                            hide-details
                            prepend-icon="mdi-earth"
                            label="Country *"
                            :rules="[rules.required]"
                            @change="selectState"
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-if="!createForm && !isEditing"
                            :readonly="!createForm && !isEditing"
                            v-model="contact.state"
                            hide-details
                            prepend-icon="mdi-map-marker-radius"
                            label="State "
                            required
                        />
                        <v-autocomplete
                            v-if="createForm || isEditing"
                            v-model="contact.state"
                            :items="this.states"
                            hide-details
                            @change="selectCity"
                            prepend-icon="mdi-map-marker-radius"
                            label="State *"
                            :rules="[rules.required]"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-if="!createForm && !isEditing"
                            :readonly="!createForm && !isEditing"
                            v-model="contact.city"
                            hide-details
                            prepend-icon="mdi-city"
                            label="City "
                            required
                        />
                        <v-combobox
                            v-if="createForm || isEditing"
                            v-model="contact.city"
                            :filter="filter"
                            :hide-no-data="!search"
                            :items="this.cities"
                            prepend-icon="mdi-city"
                            :search-input.sync="search"
                            hide-selected
                            label="City"
                            required
                            flat
                        >
                            <template v-slot:item="{ index, item }">
                                <v-text-field
                                    v-if="editing === item"
                                    v-model="editing.text"
                                    autofocus
                                    flat
                                    background-color="transparent"
                                    hide-details
                                    solo
                                    @keyup.enter="edit(index, item)"
                                ></v-text-field>
                                <span v-else>
                                    {{ item.text }}
                                </span>
                                <v-spacer></v-spacer>
                            </template>
                        </v-combobox>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            :readonly="!createForm && !isEditing"
                            v-model="contact.role"
                            hide-details
                            prepend-icon="mdi-cog"
                            label="Role"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0">
                        <v-text-field
                            v-model="contact.note"
                            hide-details
                            :readonly="!createForm && !isEditing"
                            prepend-icon="mdi-note-multiple"
                            label="Note"
                        />
                    </v-col>
                </v-row>
            </v-form>

            <small>* indicates required field</small>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="createForm"
                text
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="saveContact"
            >
                Save
            </v-btn>
            <v-btn
                :disabled="!valid"
                v-if="isEditing"
                text
                color="red"
                :loading="loading"
                @click="saveContact"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { storage } from '@/services/firebase'
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'

const countrycitystate = require('countrycitystatejson')
export default {
    name: 'ContactForm',

    props: {
        title: {
            type: String,
            required: true,
        },
        contact: {
            type: Object,
            default() {
                return {
                    id: '',
                    name: '',
                    country: '',
                    city: '',
                    note: '',
                    state: '',
                    email: '',
                    phone: '',
                    role: '',
                    picture: '',
                }
            },
        },
        originalContact: {
            type: Object,
            default: null,
        },
        contactRoles: {
            type: Array,
            default() {
                return [{ text: '', value: '' }]
            },
        },
        createForm: {
            type: Boolean,
            required: true,
        },
        clientId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            image: this.contact.profilePicURL,
            roles: ['English', 'Spanish'],
            countries: [],
            cities: [],
            states: [],
            search: null,
            inputOptions1: {
                placeholder: 'Phone 1',
            },
            inputOptions2: {
                placeholder: 'Phone 2',
            },
            shortNameCity: null,
            loadingPicture: false,
            isEditing: false,
            listCountries: null,
            profilePic: null,
            editing: null,
            editingIndex: -1,
            deleteDialog: false,
            index: null,
            company: JSON.parse(localStorage.getItem('company')),
            folder: 'clients_pictures',
            loading: false,
            error: false,
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
                number: () => this.validNumber || 'Not a valid number',
                city: v => /^[A-Za-z ]+$/.test(v) || 'Not a valid input',
            },
            deleteError: false,
        }
    },
    computed: {
        contactDiff: function() {
            if (!this.createForm) {
                return this.objDiff(this.originalContact, this.contact)
            } else {
                return null
            }
        },
    },
    watch: {
        clientId: async function() {
            await this.setLocationFromParent()
        },
    },
    async mounted() {
        await this.setLocationFromParent()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        objDiff: function(origObj, newObj) {
            function changes(newObj, origObj) {
                let arrayIndexCounter = 0
                return _.transform(newObj, function(result, value, key) {
                    if (!_.isEqual(value, origObj[key])) {
                        const resultKey = _.isArray(origObj)
                            ? arrayIndexCounter++
                            : key
                        result[resultKey] =
                            _.isObject(value) && _.isObject(origObj[key])
                                ? changes(value, origObj[key])
                                : value
                    }
                })
            }
            return changes(newObj, origObj)
        },
        async setLocationFromParent() {
            try {
                if (this.createForm) {
                    const {
                        data: { clients },
                    } = await API.getClient({ clientId: this.clientId })
                    this.contact.country = clients.country
                    this.selectState(clients.country)
                    this.contact.state = clients.state
                    this.selectCity(clients.state)
                    this.contact.city = clients.city
                }
            } catch (error) {
                console.error(error)
            }
        },

        selectCity: function(event) {
            this.cities[0] = { header: 'Select or create one' }
            let data = countrycitystate.getCities(this.shortNameCity, event)
            data.forEach(city => {
                this.cities.push({ text: city })
            })
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        selectState: function(event) {
            const index = this.listCountries.findIndex(x => x.name === event)
            this.shortNameCity = this.listCountries[index].shortName
            this.states = countrycitystate.getStatesByShort(this.shortNameCity)
        },
        update: function() {
            this.isEditing = !this.isEditing
            this.selectState(this.contact.country)
            this.selectCity(this.contact.state)
        },
        onFilePicked: function(event) {
            this.contact.profilePicURL = URL.createObjectURL(
                event.target.files[0]
            )
            this.contact.picture =
                this.contact.profilePicURL.split('/').pop() + '.jpg'
            this.image = this.contact.profilePicURL
        },
        loadImage: function(src) {
            return new Promise(resolve => {
                const img = new Image()
                img.src = src
                img.onload = () => resolve(img)
            })
        },
        toBlob: function(canvas) {
            return new Promise(resolve => {
                canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.8)
            })
        },
        openDeleteDialog: function() {
            this.deleteDialog = true
        },
        resizeImage: async function(img) {
            const canvas = document.createElement('canvas')
            let { height, width } = img
            const MAX = 250
            if (height > width) {
                if (height > MAX) {
                    width *= MAX / height
                    height = MAX
                }
            } else {
                if (width > MAX) {
                    height *= MAX / width
                    width = MAX
                }
            }
            canvas.height = height
            canvas.width = width
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            const blob = await this.toBlob(ctx.canvas)
            const name = img.src.split('/').pop() + '.jpg'
            const file = new File([blob], name, { type: 'image/jpeg' })
            return file
        },
        deleteImage: async function(img) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const refOldPictture = storageRef.child(img)
            await refOldPictture.delete()
            return true
        },
        uploadImage: async function(file) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const contactPicRef = storageRef.child(file.name)
            const result = await contactPicRef.put(file)
            return result
        },
        closeDialog: function() {
            this.$refs.form.reset()
            this.error = false
            this.contact.phone2 = ''
            this.contact.phone = ''
            this.errorMsg = null
            this.isEditing = false
            this.image = null
            this.$emit('closeDialog')
        },
        saveContact: async function() {
            if (this.createForm) {
                await this.createContact()
            } else {
                await this.updateContact()
            }
        },
        updateContact: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.contact
                if (this.contactDiff.profilePicURL) {
                    const img = await this.loadImage(this.contact.profilePicURL)
                    const imgResize = await this.resizeImage(img)
                    if (this.originalContact.picture) {
                        await this.deleteImage(this.originalContact.picture)
                    }
                    await this.uploadImage(imgResize)
                }
                if (this.contact.city && this.contact.city.text) {
                    this.contact.city = this.contact.city.text
                }
                if (this.contact.phone) {
                    this.contact.phone = this.contact.phone.replace(/ /g, '')
                }
                if (this.contact.phone2) {
                    this.contact.phone2 = this.contact.phone2.replace(/ /g, '')
                }
                delete this.contactDiff.profilePicURL
                const {
                    data: { contact },
                } = await API.updateContact(id, this.contactDiff)
                if (contact.picture) {
                    contact.profilePicURL = this.contact.profilePicURL
                }

                this.$emit('replaceContact', id, contact)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.image = null
            }
        },
        createContact: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null

                if (this.contact.profilePicURL) {
                    const img = await this.loadImage(this.contact.profilePicURL)
                    const imgResize = await this.resizeImage(img)
                    await this.uploadImage(imgResize)
                }
                if (this.contact.city) {
                    this.contact.city = this.contact.city.text
                }
                if (this.contact.phone) {
                    this.contact.phone = this.contact.phone.replace(/ /g, '')
                }
                if (this.contact.phone2) {
                    this.contact.phone2 = this.contact.phone2.replace(/ /g, '')
                }
                this.contact.clientId = this.clientId
                Object.keys(this.contact).forEach(key => {
                    if (
                        this.contact[key] === undefined ||
                        this.contact[key] === ''
                    ) {
                        delete this.contact[key]
                    }
                })
                this.contact.note = !this.contact.note ? '' : this.contact.note
                const {
                    data: { contact },
                } = await API.createContact(this.contact)

                if (contact.picture) {
                    contact.profilePicURL = this.contact.profilePicURL
                }
                this.$emit('addContact', contact)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.image = null
            }
        },

        deleteContact: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id, picture } = this.contact
                if (picture) await this.deleteImage(picture)
                await API.deleteContact(id)
                this.$emit('removeContact', id)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        this.listCountries = countrycitystate.getCountries()
        this.countries = this.listCountries.map(x => x.name)
        if (!this.createForm) {
            this.phone = _.cloneDeep(this.contact.phone)
            this.phone2 = _.cloneDeep(this.contact.phone2)
        }
    },
}
</script>

<style lang="scss" scoped>
.vue-tel-input {
    border: none;
    border-radius: 0px;
    font-size: 16px;
}
.vue-tel-input.disabled {
    opacity: 0.5;
}

.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: 'primary';
    color: 'primary';
}
</style>
